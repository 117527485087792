<template>
  <div class="box">
    <div v-html="content"></div>
  </div>
</template>

<script>
import {MerchantExplain} from "../api/httpApi";

export default {
  name: "protocol",
  data() {
    return {
      type: "",
      content: ""
    }
  },
  created() {
    this.type = this.$route.query.type
    this.getInfo()
  },
  methods: {
    getInfo() {
      let func
      switch (this.type) {
        case "merchantService":
          func = MerchantExplain
          break
      }
      if (!func) {
        return false
      }
      func()
          .then(res => {
            if (res.status !== 1) {
              return false
            }
            this.content = res.data
          })
    }
  }
}
</script>

<style scoped>

.box {
  margin: 24px 16px;
}
</style>